import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Grid, CircularProgress, makeStyles } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import CryptoJS from 'crypto-js';
import authService from 'src/services/authService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(2)
  }
}));

const SessionEndPopup = ({ open, onSubmitSuccess }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector(state => state.account);

  return (
    <Dialog
      open={open}
      fullWidth
      disableEnforceFocus
      aria-labelledby="session-end-dialog-title"
      aria-describedby="session-end-dialog-description"
      PaperProps={{
      style: {textAlign: 'center'}
    }}
    >
      <DialogTitle
        id="session-end-dialog-title"
      >
        <img src="/static/images/session_end.svg" alt="Session End" />
        <Typography color="textPrimary" style={{fontSize: '22px', fontWeight: 600, marginBottom: '8px'}}
        >
          Your session has ended
        </Typography>
        <Typography color="textPrimary" style={{fontSize: '18px', fontWeight: 400}}
        >
          Please Log In Again
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            email: account.user.email || '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Required'),
            password: Yup.string()
              .max(255)
              .required('Required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values.email !== account.user.email) {
                enqueueSnackbar('Email mismatch. Please check and try again.', {
                  variant: 'error',
                  anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                  },
                  autoHideDuration: 1000
                });
                return;
              }
              let Encrypted_Password = CryptoJS.AES.encrypt(
                values.password,
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString();
              let Encrypted_Email = CryptoJS.AES.encrypt(
                values.email,
                process.env.REACT_APP_ENCRYPTION_KEY
              ).toString();

              await authService.loginWithEmailAndPassword(
                Encrypted_Email,
                Encrypted_Password
              );
              authService.refreshToken();

              enqueueSnackbar('Login Successful', {
                variant: 'success',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top'
                },
                autoHideDuration: 1000
              });
              onSubmitSuccess();
            } catch (error) {
              const message = 'Incorrect Email or Password';
              setStatus({ success: false });
              setErrors({ submit: message });
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top'
                },
                autoHideDuration: 1000
              });
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.textField}>
                  <Field
                    name="email"
                    as={TextField}
                    fullWidth
                    label="Email ID"
                    type="email"
                    variant="outlined"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} className={classes.textField}>
                  <Field
                    name="password"
                    as={TextField}
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
              </Grid>
              <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  disableElevation
                  style={{
                      width: 100,
                      height: 40,
                    }}
                >
                  {isSubmitting ? <CircularProgress size={28} style={{ color: '#fff'}} /> : 'LOG IN'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

SessionEndPopup.propTypes = {
  open: PropTypes.bool.isRequired
};

export default SessionEndPopup;
