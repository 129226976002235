import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import CryptoJS from 'crypto-js';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const TWO_FACTOR_AUTH = '@account/two-factor-auth';
export const UPDATE_PROFILE = '@account/update-profile';

export function loginWithOtp(email, token) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await authService.loginWithEmailAndOtp(email, token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function login(email, password, setEmail) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);
      let dec_email = email
      var email_bytes  = CryptoJS.AES.decrypt(dec_email, process.env.REACT_APP_ENCRYPTION_KEY);
      dec_email = email_bytes.toString(CryptoJS.enc.Utf8);
      if (user === 'TwoFactorAuth'){
        dispatch({ type: TWO_FACTOR_AUTH });
        setEmail(dec_email)
      }
      else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user
          }
        });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async (dispatch) => {
    if (authService.getAccessToken() === "no_value") {
      authService.setSession("no_value");
      dispatch({
        type: LOGIN_FAILURE
      });
    } else {
      authService.logout();
    }
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.put('/user/update', update);
  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data
      })
    );
  };
}
