/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  File as FileIcon,
  UserCheck as Consult, 
  Book as Journal,
  Clipboard as JobIcon,
  CreditCard,
  Users,
} from 'react-feather';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { LocalHospital } from '@material-ui/icons';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import Files from 'src/views/projects/ProjectDetailsView/Files';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SummarizeIcon from '@mui/icons-material/Summarize';
import jobsInDangerService from 'src/services/jobsInDanger';

const showLeadsToEmails = ["rlangenback@medtigo.com", "rshinde@medtigo.com", "rkulkarni@medtigo.com", "reports@medtigo.com", "apatil@medtigo.com", "rswami@medtigo.com", "ashahi@medtigo.com", "sdave@medtigo.com"]

const hasJobsAtRiskAccess = (email) => {
  
  const authorizedEmails = [
    'ashahi@medtigo.com',
    'rswami@medtigo.com',
    'rkulkarni@medtigo.com',
    'rlangenback@medtigo.com',
    'smontiel@medtigo.com',
    'reports@medtigo.com',
    'rshinde@medtigo.com'
  ];

  return authorizedEmails.includes(email);
}


const navConfigAnalyst = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Certificates',
        icon: FileIcon,
        href: '/certificates',
        items: [
          {
            title: 'CME Certificates',
            href: '/certificates/cme'
          },
          {
            title: 'Regulatory Certificates',
            href: '/certificates/regulatory'
          },
          // {
          //   title: 'License Report',
          //   href: '/certificates/reports'
          // },
          {
            title: 'Task Dashboard',
            href: '/certificates/task'
          },
          {
          title: 'Credentialing',
          href: '/credentialing'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/users',
        items: [
          {
            title: 'List Users',
            href: '/users'
          },
          {
            title: 'Add Users',
            href: '/user/add'
          }
        ]
      },
      {
        title: 'Accounts',
        icon: AccountBalanceIcon,
        href: '/invoice',
        items: [
          {
            title: 'Add Invoice',
            href: '/invoice/add'
          },
          {
            title: 'List Invoice',
            href: '/viewInvoice'
          },
        ]
      }
    ]
  }
];

const navConfigAccounts = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Accounts',
        icon: AccountBalanceIcon,
        href: '/invoice',
        items: [
          {
            title: 'Add Invoice',
            href: '/invoice/add'
          },
          {
            title: 'List Invoice',
            href: '/viewInvoice'
          },
          {
            title: 'Meals Expense',
            href: '/viewMealsExpense'
          },
          {
            title: 'Site Expense',
            href: '/viewSiteExpense'
          },
          {
            title: 'Travel Expense',
            href: '/viewTravelExpense'
          },
        ]
      }
    ]
  },
];

const navConfigScheduleReport = [
  {
    title: "Schedule Report",
    icon: SummarizeIcon,
    href: "/scheduleReport",
  },
  {
    title:'Payment Request',
    icon: CreditCard,
    href:'/schedules/payment-requests'
  }
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    width: 42,
    height: 43
  }
}));



function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try{
        setLoading(true)
        const {total: first} = await jobsInDangerService.getInitiatedMoreThan30Days(10, 1);
        //  const {total: second}= await jobsInDangerService.getInitiatedAndJobCreated(10, 1);
        const {total: third}= await jobsInDangerService.getApplicationSubmittedMoreThan14Days(10, 1);
        //  const {total: fourth}= await jobsInDangerService.getApplicationSubmittedAndJobCreated(10, 1);
        const {total: fifth}= await jobsInDangerService.getNotGrantedSince120days(10, 1);
        setCount(first + third + fifth)
      }catch(err){

      }finally{
        setLoading(false)
      }
    })()
  }, [])

  const navConfig = [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Certificates',
          icon: FileIcon,
          href: '/certificates',
          items: [
            {
              title: 'CME Certificates',
              href: '/certificates/cme'
            },
            {
              title: 'Regulatory Certificates',
              href: '/certificates/regulatory'
            },
            {
              title: 'Task Dashboard',
              href: '/certificates/task'
            }
          ]
        }
      ]
    },
    {
      subheader: 'Management',
      items: [
        {
          title: 'Users',
          icon: UsersIcon,
          href: '/users',
          items: [
            {
              title: 'List Users',
              href: '/users'
            }
          ]
        },
        {
          title: 'Hospitals',
          icon: LocalHospital,
          href: '/hospitals',
          items: [
            {
              title: 'List Hospitals',
              href: '/hospitals'
            }
          ]
        },
        {
          title: 'Departments',
          icon: BusinessCenterIcon,
          href: '/departments',
          items: [
            {
              title: 'List Departments',
              href: '/departments'
            }
          ]
        },
  
        // {
        //   title: 'Progresses',
        //   icon: BarChartIcon,
        //   href: '/progress',
        //   items: [
        //     {
        //       title: 'Course Progress Listing',
        //       href: '/progress'
        //     }
        //   ]
        // }
      ]
    }
  ];
  
  let logAccess = false;
  if(user.email ==='ashahi@medtigo.com' || user.email ==='rswami@medtigo.com' || user.email ==='rlangenback@medtigo.com' || user.email === "gsukale@medtigo.com"){
    logAccess = true;
  }
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (user.role === 'admin' && navConfig[1].items[0].items.length === 1) {
    navConfig[1].items[0].items.push({
      title: 'Assign Roles',
      href: '/roles/assign'
    });
    navConfig[1].items[0].items.push({
      title: 'Add Users',
      href: '/user/add'
    });
    navConfig[1].items[0].items.push({
      title: 'Feedbacks',
      href: '/user/feedbacks'
    });
    navConfig[1].items[1].items.push({
      title: 'Bulk Consent',
      href: '/hospital/consent/'
    });
  }
  if (user.role === 'admin' && navConfig[1].items.length === 3) {
    navConfig[1].items.push({
      title: 'Progresses',
      icon: BarChartIcon,
      href: '/progress',
      items: [
        {
          title: 'Course Progress Listing',
          href: '/progress'
        },
        {
          title: 'Quiz Analytics',
          href: '/quiz-analytics'
        }
      ]
    });
  }
  if (user.role === 'admin' && navConfig[1].items.length === 4) {
    navConfig[1].items.push({
      title: 'Orders',
      icon: LibraryBooks,
      href: '/orders',
      items: [
        {
          title: 'List Orders',
          href: '/orders'
        }
      ]
    });
  }
  if (user.role === 'admin' && navConfig[1].items.length === 5) {
    navConfig[1].items.push({
      title: 'Accounts',
      icon: AccountBalanceIcon,
      href: '/invoice',
      items: [
        {
          title: 'Add Invoice',
          href: '/invoice/add'
        },
        {
          title: 'List Invoice',
          href: '/viewInvoice'
        },
        {
          title: 'Meals Expense',
          href: '/viewMealsExpense'
        },
        {
          title: 'Site Expense',
          href: '/viewSiteExpense'
        },
        {
          title: 'Travel Expense',
          href: '/viewTravelExpense'
        },
      ]
    });
  }

  if(navConfig[1].items.length === 6){
    if(showLeadsToEmails.includes(user.email)){

    navConfig[1].items.push({
      title: 'Consultation Requests',
      icon: Consult,
      href: '/consultation',
    items: [
        {
          title: 'License Requests',
          href: '/licenseRequest'
        },
        {
          title: 'medtigo Plus Requests',
          href: '/medtigoPlusRequest'
        },
        {
          title: 'Invoices',
          href: '/invoices'
        },
      ]
    });
    }else {

    navConfig[1].items.push({
      title: 'Consultation Requests',
      icon: Consult,
      href: '/consultation',
      items: [
        {
          title: 'Invoices',
          href: '/invoices'
        },
      ]
    });
    }
  }

  if (user.isMedtigoJournalAdmin) {
    navConfig[1].items.push({
      title: 'Journal',
      icon: Journal,
      href: '/journal',
      items: [
        {
          title: 'Add User',
          href: '/journalJobs/add-user'
        },
        {
          title: 'Assign Role',
          href: '/journalJobs/assign-role'
        },
        {
          title: 'Inquiries',
          href: '/journalRequest'
        },
        {
          title: 'Add Manuscript',
          href: '/journalJobs/add'
        },
        {
          title: 'Manuscripts',
          href: '/journalJobs'
        },
        {
          title: 'Task Dashboard',
          href: '/journalJobs/tasks'
        },
      ]
    });
  }


  
  // if (user.scheduling && navConfig[1].items.length === 5) {
  if (
    (user.scheduling && navConfig[1].items.length === 3) ||
    (user.scheduling &&
      user.role === 'admin' &&
      navConfig[1].items.length === 8)
  ) {
    navConfig[1].items.push({
      title: 'Scheduling',
      icon: BusinessCenterIcon,
      href: '/schedules',
      items: [
        {
          title: 'Shift Schedule Viewer',
          href: '/schedules/dndcalendar'
        },
        {
          title: 'Shift Schedules',
          href: '/schedules'
        },
        {
          title: 'Upload Open Shifts',
          href: '/open/shifts'
        },
        {
          title: 'Shifts',
          href: '/shifts'
        },
        {
          title: 'Shift Requests',
          href: '/schedules/requests'
        },
        // {
        //   title:'DndCalender',
        //   href:'/schedules/dndcalendar'
        // },
        {
          title:'Schedule Report',
          href:'/schedules/report'
        },
        {
          title:'Payment Request',
          href:'/schedules/payment-requests'
        }
      ]
    });
  }
  if (user.role === 'admin' && navConfig[1].items.length === 9) {
    navConfig[1].items.push({
      title: 'Agencies Jobs',
      icon: JobIcon,
      href: '/consultation',
      items: [
        {
          title: 'Job Requests',
          href: '/jobRequests'
        },
      ]
    });
  }
  if (user.role === 'admin' && navConfig[1].items.length === 10) {
    navConfig[1].items.push({
      title: 'Developer Tools',
      icon: JobIcon,
      href: '/developertools',
      items: [
        {
          title: 'SQL',
          href: '/developertools/sql'
        },
      ]
    });
  }
  if (
    user.role === 'admin' &&
    navConfig[0].items.length === 1 &&
    user.email === 'rswami@medtigo.com'
  ) {
    navConfig[0].items.push({
      title: 'Super Admin Tracking',
      icon: TrackChangesIcon,
      href: '/admin-tracking',
      items: [
        {
          title: 'Super Admin Tracking',
          href: '/admin-tracking'
        }
      ]
    });

    navConfig[0].items.push({
      title: 'User Tracking',
      icon: TrackChangesIcon,
      href: '/user-tracking',
      items: [
        {
          title: 'User Tracking',
          href: '/user-tracking'
        }
      ]
    });
  }
  if (
    user.role === 'admin' &&
    navConfig[0].items.length === 1 &&
    user.email === 'rkulkarni@medtigo.com'
  ) {
    navConfig[0].items.push({
      title: 'Super Admin Tracking',
      icon: TrackChangesIcon,
      href: '/admin-tracking',
      items: [
        {
          title: 'Super Admin Tracking',
          href: '/admin-tracking'
        }
      ]
    });

    navConfig[0].items.push({
      title: 'User Tracking',
      icon: TrackChangesIcon,
      href: '/user-tracking',
      items: [
        {
          title: 'User Tracking',
          href: '/user-tracking'
        }
      ]
    });
  }

  if (user.role === 'admin' && logAccess && navConfig[0].items[0].items.length === 3) {
    navConfig[0].items[0].items.push({
        title: 'License Cron Log',
        href: '/certificates/cronlog'
    });
  }
  // if (user.role === 'admin' && user.email === "ashadfhi@medtigo.com" && navConfig[0].items[0].items.length === 4) {
  //   navConfig[0].items[0].items.push({
  //     title: <span>Jobs At Risk: { loading ? <CircularProgress size="10px"/> : <span style={{color: '#EF0107'}}>({count})</span>}</span>,
  //     href: '/certificates/dangerJobs'
  //   },);
  // }
  if (user.role === 'admin' && logAccess && navConfig[0].items[0].items.length === 4) {
    navConfig[0].items[0].items.push({
        title: 'Simulation Cases',
        href: '/simulation/casesView'
    });
  }
  if (user.role === 'admin' && navConfig[0].items[0].items.length === 5) {
    navConfig[0].items[0].items.push({
      title: 'Credentialing',
    href: '/credentialing'
    });
  }

  if (!navConfig[0].items[0].items.find(it=>it.title=='Average License Processing Time') && user.role === 'admin' ) {
    navConfig[0].items[0].items.push({
      title: 'Average License Processing Time',
      href: '/certificates/reports'
    });
  }

  if (!navConfigAnalyst[0].items[0].items.find(it=>it.title=='Average License Processing Time') && user.role === 'analyst_admin') {
    navConfigAnalyst[0].items[0].items.push({
      title: 'Average License Processing Time',
      href: '/certificates/reports'
    });
  }

  if (!navConfig[0].items[0].items.find(it=>it.title=='Average License Processing Time') && user.email=='robin_banner@teamhealth.com') {
    navConfig[0].items[0].items.push({
      title: 'Average License Processing Time',
      href: '/certificates/reports'
    });
  }

  if (!navConfig[0].items[0].items.find(it=>it.title=='Jobs At Risk') && hasJobsAtRiskAccess(user.email)) {
    navConfig[0].items[0].items.splice(2, 0, {
        title: <span>Jobs At Risk: { loading ? <CircularProgress size="10px"/> : <span style={{color: '#EF0107'}}>({count})</span>}</span>,
        href: '/certificates/dangerJobs'
    });
}

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={'/static/profile.png'}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.first_name
                ? `${user.first_name} ${user.last_name}`
                : user.email}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {user.role !== 'analyst_admin' && user.role !== 'accounts' && (
          <Box p={2}>
            {navConfig.map(config => (
              <List
                key={config.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        )}
        {user.role === 'analyst_admin' && (
          <Box p={2}>
            {navConfigAnalyst.map(config => (
              <List
                key={config.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
        )}
        {user.role === 'accounts' && (
          <Box p={2}>
            {navConfigAccounts.map(config => (
              <List
                key={config.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            )).concat(navConfigScheduleReport.map(config => {
              return <List key={config.subheader} subheader={
                <ListSubheader disableGutters disableSticky>{config.subheader}</ListSubheader>
              }>
                <NavItem title={config.title} href={config.href} icon={config.icon}></NavItem>
              </List>
            }))}
          </Box>
        )}
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              href="mailto:support@medtigo.com"
              target="_blank"
            >
              Contact Support
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
